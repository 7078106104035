.layoutContainer {
  & > :global(.Polaris-Layout) {
    align-items: stretch;

    :global(.Polaris-LegacyCard) {
      height: 100%;
    }
  }
}

.cardContainer {
  height: 100%;

  & > :global(.Polaris-LegacyCard) {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
}
